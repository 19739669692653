<template>
  <div class="wrap">
    <div
      class="nav_bar"
      :style="{
        padding:
          medicalRecordTypes == '血压报告' && url
            ? '10px 0px 10px 0px'
            : '10px 0px 50px 0px',
      }"
    >
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 15px"
        @click="onClickLeft"
      />
      <span style="font-size: 20px; color: #fff; font-weight: bold"
        >查看详情</span
      >
    </div>

    <div v-if="type == 0">
      <div v-if="medicalRecordTypes == '血压报告' && url">
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="url"
          :page="i"
          style="display: inline-block; width: 100%"
        ></pdf>
      </div>
      <div v-else>
        <van-loading type="spinner" v-show="loading" />

        <div
          v-html="recordDetail"
          class="content"
          id="table_content_right"
          :style="{ lineHeight: '2',fontFamily: '宋体'}"
        ></div>
        <van-empty v-show="showEmpty" description="暂无数据" class="content" />
      </div>
    </div>

    <div v-if="type == 1">
      <div class="content">
        <div class="title">检验结果</div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检验单号
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ testNo }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检验项目
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ subject }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检验医生
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ reporter }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检验时间
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ resultsRptDateTime }}
          </div>
        </div>
        <div class="label">
          <div style="width: 55%">项目指标</div>
          <div style="width: 20%">结果</div>
          <div style="width: 25%; text-align: right">参考范围</div>
        </div>
        <div style="padding-top: 10px">
          <div class="detail" v-for="(item, i) in inspectionList" :key="i">
            <div style="width: 50%; margin-right: 10px">
              {{ item.reportItemName }}
              <span
                class="tipsHeight"
                :style="
                  item.abnormalFlag == 'H' ? 'color:#FF1C1C' : 'color:#1AB29B'
                "
              >
                {{
                  item.abnormalFlag == "H"
                    ? "↑"
                    : item.abnormalFlag == "L"
                    ? "↓"
                    : ""
                }}
              </span>
            </div>
            <div style="width: 30%">
              {{ item.result ? item.result : ""
              }}{{ item.units ? item.units : "" }}
            </div>
            <div style="width: 20%; text-align: right">
              {{ item.printContext }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type == 2">
      <div class="content">
        <div class="title">检查结果</div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查单号
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ testNo }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查项目
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ examItems }}
          </div>
        </div>

        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查医生
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ reporter }}
          </div>
        </div>

        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查时间
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ reportDateTime }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查印象
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ checkListInfo.impression }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            检查所见
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ checkListInfo.description }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="type == 3">
      <div class="content">
        <div class="title">医嘱</div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            医嘱编码
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ doctorsAdviceInfo[0].orderCode }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            医嘱医生
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ doctorsAdviceInfo[0].doctor }}
          </div>
        </div>
        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            医嘱护士
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ doctorsAdviceInfo[0].nurse }}
          </div>
        </div>

        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            医嘱正文
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{ doctorsAdviceInfo[0].orderText }}
          </div>
        </div>

        <div class="title_info">
          <div style="color: #999; font-size: 15px; font-weight: bold">
            医嘱时间
          </div>
          <div
            style="
              color: #333;
              font-size: 15px;
              font-weight: bold;
              margin-top: 5px;
            "
          >
            {{
              doctorsAdviceInfo[0].startDateTime +
              "-" +
              doctorsAdviceInfo[0].enterDateTime
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="type == 5" class="box">
      <van-loading type="spinner" v-show="loading" />
      <div
        v-html="medicalRecordsDetail"
        class="content"
        id="table_content_right"
        :style="{ lineHeight: '2' }"
      ></div>
      <van-empty v-show="showEmpty" description="暂无数据" class="content" />
    </div>
  </div>
</template>
<script>
import {
  getDigitalMedicalRecordDetail,
  getInspectionDetail,
  getCheckDetail,
  getMedicalRecordsDetail,
} from "@/api/patients";
import { doctorsAdvice } from "@/api/doctorAdvice";
import pdf from "vue-pdf";

export default {
  components: { pdf },
  data() {
    return {
      loading: false,
      showEmpty: false,
      htmlFilePath: "",
      medicalRecordsDetail: "",
      subject: "",
      reporter: "",
      resultsRptDateTime: "",
      reportDateTime: "",
      examItems: "",
      type: "",
      docId: "",
      testNo: "",
      patientId: "",
      tenantId: localStorage.getItem("tenantId"),
      visitId: "",
      visitType: "",
      recordDetail: "",
      activeKey: 0,
      medicalRecordTypes: "",
      deptCode: "",
      url: "",
      pdfPage: 1,
      src: null,
      numPages: undefined,
      inspectionList: [],
      checkListInfo: {},
      doctorsAdviceInfo: [],
    };
  },
  created() {
    if (this.$route.query.subject) {
      this.subject = this.$route.query.subject;
    }
    if (this.$route.query.resultsRptDateTime) {
      this.resultsRptDateTime = this.$route.query.resultsRptDateTime;
    }
    if (this.$route.query.examItems) {
      this.examItems = this.$route.query.examItems;
    }
    if (this.$route.query.reportDateTime) {
      this.reportDateTime = this.$route.query.reportDateTime;
    }
    if (this.$route.query.testNo) {
      this.testNo = this.$route.query.testNo;
    }
    if (this.$route.query.reporter) {
      this.reporter = this.$route.query.reporter;
    }
    if (this.$route.query.htmlFilePath) {
      this.htmlFilePath = this.$route.query.htmlFilePath;
    }
  },
  mounted() {
    document.getElementById("container").style.background = "#F6F6F6";

    this.type = this.$route.query.type;
    if (this.$route.query.testNo && this.type == 1) {
      this.testNo = this.$route.query.testNo;
      this.getInspectionDetailInfo();
    }
    if (this.$route.query.testNo && this.type == 2) {
      this.testNo = this.$route.query.testNo;
      this.getCheckDetailInfo();
    }

    if (this.type == 3) {
      this.getDoctorsAdviceInfo();
    }
    if (this.type == 5) {
      this.getMedicalRecordsDetailInfo();
    }
    this.docId = this.$route.query.docId;
    this.patientId = this.$route.query.patientId;
    this.visitId = this.$route.query.visitId;
    this.visitType = this.$route.query.visitType;
    this.medicalRecordTypes = this.$route.query.medicalRecordTypes; // 判断是否等于血压报告
    this.activeKey = this.$route.query.activeKey; // 判断是否等于血压报告
    this.deptCode = this.$route.query.deptCode; // 判断是否等于血压报告
    this.url = this.$route.query.url; // 判断是否等于血压报告PDF
    if (this.url) {
      this.src = pdf.createLoadingTask({
        url: this.url,
      });
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    }
    if (this.type == 0) {
      this.getMedicalRecordDetail();
    }
  },

  methods: {
    // 获取电子检查单
    getMedicalRecordDetail() {
      this.loading = true;
      getDigitalMedicalRecordDetail(
        this.docId,
        this.patientId,
        this.tenantId,
        this.visitId,
        this.visitType
      )
        .then((res) => {
          if (res.data.code == "200") {
            this.recordDetail = res.data.data.text || {};
            console.log(this.recordDetail,'this.recordDetail')
            this.loading = false;
            if (!this.recordDetail) {
              this.showEmpty = true;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },

    //获取检验详情
    getInspectionDetailInfo() {
      this.loading = true;
      getInspectionDetail(this.testNo)
        .then((res) => {
          if (res.data.code == 200) {
            this.inspectionList = res.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //获取检查详情
    getCheckDetailInfo() {
      getCheckDetail(this.testNo).then((res) => {
        if (res.data.code == 200) {
          this.checkListInfo = res.data.data;
          console.log(this.checkListInfo, "this.checkListInfo");
        }
      });
    },

    //医嘱详情
    getDoctorsAdviceInfo() {
      this.loading = true;
      let row = {};
      row.patientId = this.$route.query.patientId;
      row.visitId = this.$route.query.visitId;
      row.repeatIndicator = 1;
      row.orderNo = this.$route.query.orderNo;
      row.orderSubNo = this.$route.query.orderSubNo;

      doctorsAdvice(row)
        .then((res) => {
          if (res.data.code == 200) {
            this.doctorsAdviceInfo = res.data.data.resps || [];
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    //获取急诊详情
    getMedicalRecordsDetailInfo() {
      this.loading = true;
      getMedicalRecordsDetail(this.htmlFilePath)
        .then((res) => {
          if (res.data.code == 200) {
            this.medicalRecordsDetail = res.data.data;
            this.loading = false;
            if (!this.medicalRecordsDetail) {
              this.loading = false;
              this.showEmpty = true;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },

    onClickLeft() {
      // this.$router.back();
      this.$router.push({
        path: "/patientsInfo",
        query: {
          activeKey: this.activeKey,
          patientId: this.patientId,
          visitId: this.visitId,
          deptCode: this.deptCode,
          medicalRecordTypes: this.medicalRecordTypes,
        },
      });
    },
    onClickInfo() {
      this.$router.push({
        path: "/jcInfo",
        query: { activeKey: this.activeKey },
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-loading {
  left: 45%;
  top: 30px;
}
.wrap {
  background: #f6f6f6;
  // height: 100vh;
  height: 100%;
  margin-bottom: -50px;

  .nav_bar {
    color: #fff;
    background-color: #1578f9;
    padding: 10px 0px 50px 0px;
    text-align: center;
  }

  .user_info {
    width: 342px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 0px rgb(7 2 14 / 6%);
    border-radius: 10px;
    margin: -30px auto 0;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;

    .left {
      width: 43px;
      height: 43px;
      background: #fe5656;
      border-radius: 10px;
      font-size: 21px;
      line-height: 43px;
      text-align: center;
      color: #ffffff;
      margin-right: 20px;
    }

    .right {
      margin-top: -5px;

      .name {
        font-weight: bold;
        color: #333333;
        font-size: 19px;
        margin-right: 20px;
      }

      .sex {
        font-size: 13px;
        // font-weight: bold;
        color: #999999;
        margin-top: 5px;
      }

      .num {
        font-size: 16px;
        // font-weight: bold;
        color: #999999;
        margin-top: 5px;
      }
    }
  }

  .content {
    // width: 344px;
    // min-height: 80vh;
    background: #ffffff;
    border-radius: 10px;
    // margin: -30px auto 20px;
    margin: -30px 20px 20px 20px;
    padding: 10px;
    box-sizing: border-box;

    span {
      font-size: 14px;
    }

    .title {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
      padding: 15px 0 10px 15px;
    }

    .title:before {
      width: 3px;
      height: 11px;
      float: left;
      content: "";
      display: block;
      margin-right: 5px;
      background: #1091fc;
      margin-top: 6px;
    }

    .title_info {
      padding: 15px 0 10px 20px;
    }
  }

  .label {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    background-color: #efefef;
    padding: 10px 20px;
    color: #adadad;
    font-weight: bold;
  }

  .detail {
    display: flex;
    // justify-content: space-between;
    font-size: 15px;
    background-color: #fff;
    padding: 10px 5px;
    color: #333;
    font-weight: bold;
  }
}
</style>
<style>
#table_content_right span {
  font-size: 14px !important;
}
</style>
