import request from '@/router/axios';

export const getList = (data) => request({
  url: '/api/blade-handheld-hospital/enterprise/wechat/doctorsAdvice',
  method: 'post',
  data,
});

// /blade-handheld-hospital/enterprise/wechat/doctorsAdvice
export const doctorsAdvice = (data) => request({
  url: '/api/blade-handheld-hospital/enterprise/wechat/doctorsAdvice',
  method: 'post',
  data,
});
